<template>
  <div>
    <ek-dialog
      ref="dialog"
      title="إضافة مستخدم"
      size="md"
      placeholder="ابحث عن مستخدم معين"
      btnText="مستخدم جديد"
      @ok="submitForm"
      @close="resetForm"
      @search="setSearch"
    >
      <template #body>
        <user-form class="w-100" ref="userForm" @closeModal="closeDialog()"> </user-form>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import userForm from "./UserForm.vue";
export default {
  components: {
    userForm,
  },
  data: () => ({}),
  methods: {
    submitForm() {
      this.$refs.userForm.submit();
    },
    closeDialog() {
        this.$refs.dialog.close();
    },
    resetForm() {
      this.$refs.userForm.reset();
    },
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["accountName", "userName", "dateCreate", "subCount"],
        query,
      });
    },
  },
};
</script>
