<template>
  <b-form>
    <ValidationObserver ref="form">
      <b-row class="no-gutters">
        <b-col cols="12">
          <ek-input-text
            name="اسم "
            label="الاسم الكامل "
            placeholder="ادخل الاسم الكامل "
            :rules="[{ type: 'required' }]"
            v-model="userDto.name"
          />
        </b-col>
        <b-col cols="12">
          <ek-input-text
            name="userName "
            label="اسم المستخدم  "
            placeholder="ادخل اسم المستخدم"
            :rules="[{ type: 'required' }]"
            v-model="userDto.userName"
          />
        </b-col>
        <b-col cols="12">
          <!-- <div class="d-flex align-items-center">
            <b-form-checkbox v-model="useEmail" switch> </b-form-checkbox>
            <label for="">التسجيل باستخدام البريد الالكتروني</label>
          </div>
          <div v-if="useEmail">
            <label class="my-1" style="font-weight: bold">البريد الالكتروني</label>
            <ValidationProvider
              v-slot="{ errors, validated }"
              state=""
              rules="required|email"
              name="البريد الالكتروني"
            >
              <b-form-input
                type="email"
                :state="validated ? (errors[0] ? false : true) : null"
                v-model="userDto.accountVerification"
                placeholder=" ادخل البريد الالكتروني "
              >
              </b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div> -->
          <ek-input-text
            label="البريد الإلكتروني "
            name="البريد "
            placeholder="ادخل البريد الإلكتروني "
            v-model="userDto.email"
            :rules="[
              {
                type: 'required',
                message: 'البريد الإلكتروني  مطلوب',
              },
              {
                type: 'email',
                message: ' البريد الإلكتروني غير صالح',
              },
            ]"
          ></ek-input-text>
        </b-col>
        <b-col cols="12">
          <ek-input-text
            type="password"
            name="password"
            label="كلمة المرور"
            placeholder="ادخل كلمة المرور"
            :rules="[{ type: 'required', message: 'أدخل كلمة المرور' }]"
            v-model="userDto.password"
          />
        </b-col>
        <b-col cols="12">
          <ek-input-text
            type="password"
            name=" تأكيد كلمة المرور "
            label="تأكيد كلمة المرور"
            placeholder="ادخل كلمة المرور"
            :rules="[
              { type: 'required' },
              { type: 'confirmed:password', message: 'كلمة المرور غير متطابقة' },
            ]"
          />
        </b-col>
        <b-col cols="12">
          <ek-input-select
            :rules="[{ type: 'required' }]"
            name="الدولة"
            v-model="userDto.countryId"
            label="الدولة"
            textLabel="langName"
            :options="countries"
          />
        </b-col>
        <b-col cols="12">
          <ek-input-select
            v-model="userDto.cityId"
            name="المدينة"
            label="المدينة"
            textLabel="langName"
            :options="cities"
          />
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      useEmail: false,
    };
  },
  computed: {
    ...mapState({
      cities: ({ locations }) => locations.cities,
      countries: ({ locations }) => locations.countries,
      userDto: ({ users }) => users.addUserForm,
    }),
  },
  methods: {
    ...mapActions(["addUser"]),
    submit() {
      this.$refs.form.validate().then((s) => {
        if (s) {
          this.addUser({
            ...this.userDto,
            accountVerification: this.userDto.accountVerification.replace(" ", ""),
          });
          this.$emit("closeModal");
        }
      });
    },
    reset() {
      this.$refs["form"].reset();
      this.$store.commit("Set_User_Dto");
    },
  },
  useEmail() {
    this.$refs["activityForm"].reset();
    this.activityForm.accountVerification = "";
  },
  handleChange() {},
};
</script>
